import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';

/**
 * views와 components를 구분짓는것은 router를 통해 보여주는 컴포넌트는 views폴더에 넣고, 그것이 아니라면 components폴더에 넣는것이다
 */

//  {
// path: '/about',
// name: 'about',
// // route level code-splitting
// // this generates a separate chunk (about.[hash].js) for this route
// // which is lazy-loaded when the route is visited.
// component: () => import(/* webpackChunkName: "about", webpackPrefetch: true */ '../views/AboutView.vue')

const routes = [
    {
        path: '/',
        redirect: '/dashboard',
        name: 'Home',
        meta: {
            requiresAuth: true
        }/*,
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: () => import('@/views/Dashboard'),
            },
        ]*/
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
            requiresAuth: true
        },
        component: () => import('@/views/DashboardView'),
    },
    {
        path: '/domestic',
        name: 'Domestic',
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'transaction',
                name: 'DomesticTransaction',
                component: () => import('@/views/domestic/DomesticTransactionView'),
            },
            {
                path: 'summary',
                name: 'DomesticSummary',
                component: () => import('@/views/domestic/DomesticSummaryView'),
            },
            {
                path: 'statistics',
                name: 'DomesticStatistics',
                component: () => import('@/views/domestic/DomesticStatisticsView'),
            },
            {
                path: 'logs',
                name: 'DomesticLogs',
                component: () => import('@/views/domestic/DomesticLogsView'),
            },
        ]
    },
    {
        path: '/cross-border',
        name: 'CrossBorder',
        meta: {
            requiresAuth: true
        },
        children: [
            // {
            //     path: 'visits',
            //     name: 'CrossBorderVisits',
            //     component: () => import('@/views/cross-border/CrossBorderVisitsView'),
            // },
            {
                path: 'events',
                name: 'CrossBorderEvents',
                component: () => import('@/views/cross-border/CrossBorderEventsView'),
            },
            {
                path: 'statistics/:productId',
                name: 'CrossBorderStatistics',
                component: () => import('@/views/cross-border/CrossBorderStatisticsView'),
            },
        ]
    },
    {
        path: '/fds',
        name: 'FDS',
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'fraud-detection',
                name: 'FraudDetection',
                component: () => import('@/views/fds/FraudDetectionView'),
            },
            {
                path: 'assessing-conformity',
                name: 'AssessingConformity',
                component: () => import('@/views/fds/AssessingConformityView'),
            },
        ]
    },
    {
        path: '/reporting',
        name: 'Reporting',
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'explorer',
                name: 'BlockchainExplorer',
                component: () => import('@/views/reporting/BlockchainExplorerView'),
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/LoginView'),
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

export default router
