<template>
    <router-view />
</template>

<script>
    import { onMounted } from 'vue'
    // https://deepinder.me/creating-a-real-time-chat-app-with-vue-socket-io-and-nodejs
    import SocketioService from './services/socketio.service'

    export default {
        name: 'App',
        components: {},
        created() {
            SocketioService.setupSocketConnection()

            onMounted(() => {
            // set theme
                document.documentElement.classList.toggle('custom-dd-nav-dark');                
            })
        },
        beforeUnmount() {
            SocketioService.disconnect()
        }        
    }
</script>