 // JWT - JSON Web Token

const ID_TOKEN_KEY = "scan_id_token"

export const getToken = () => {
     //return window.localStorage.getItem(ID_TOKEN_KEY)
    return window.localStorage.getItem(ID_TOKEN_KEY) || ''
}

export const saveToken = token => {
    window.localStorage.setItem(ID_TOKEN_KEY, token)
}

export const destroyToken = () => {
    window.localStorage.removeItem(ID_TOKEN_KEY)
}

export default { getToken, saveToken, destroyToken }